require('./bootstrap');

function updateMousePosition(event) {
   var windowWidth = window.innerWidth;
   var windowHeight = window.innerHeight;

   var mouseXpercentage = Math.round(event.pageX / windowWidth * 100);
   var mouseYpercentage = Math.round(event.pageY / windowHeight * 100);

   const wrap1_radial_gradient = document.getElementById("wrap1");
   wrap1_radial_gradient.style.background = 'radial-gradient(circle at ' + mouseXpercentage + '% ' + mouseYpercentage + '%, #2A3526 0%, #151517 50%)';
 }

// window.addEventListener("mousemove", updateMousePosition);

